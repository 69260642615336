$(document).ready(function () {

    if ($(window).width() > 1024) {
        $(window).scroll(function () {
            var y_scroll_pos = window.pageYOffset;
            var scroll_pos1 = 230;
            // set to whatever you want it to be

            if (y_scroll_pos > scroll_pos1) {
                $("#myBtn").css("display", 'block');
            } else {
                $("#myBtn").css("opacity", 1);
            }

            if (y_scroll_pos < scroll_pos1) {
                $("#myBtn").css("display", 'none');
            } else {
                $("#myBtn").css("opacity", 'block');
            }

            if (y_scroll_pos > 1800) {
                $("#myBtn").css("display", 'none');
            }
        });
    }


    $('.owl-carousel').owlCarousel({
        loop: true,
        margin: 10,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 2
            }
        }
    });


    window.onscroll = function () {
        var header = document.getElementById("myHeader");
        if (header) {
            var sticky = header.offsetTop;
            if (window.pageYOffset > sticky) {
                header.classList.add("sticky");
            } else {
                header.classList.remove("sticky");
            }
        }
    };

    var $animation_elements = $('.anim');
    var $window = $(window);

    function check_if_in_view() {
        var window_height = $window.height();
        var window_top_position = $window.scrollTop();
        var window_bottom_position = (window_top_position + window_height);

        $.each($animation_elements, function () {
            var $element = $(this);
            var element_height = $element.outerHeight();
            var element_top_position = $element.offset().top;
            var element_bottom_position = (element_top_position + element_height);

            //check to see if this current container is within viewport
            if ((element_bottom_position >= window_top_position) &&
                (element_top_position <= window_bottom_position)) {
                $element.addClass('animated');
            } else {
                $element.removeClass('animated');
            }
        });
    }

    $window.on('scroll resize', check_if_in_view);
    $window.trigger('scroll');

    $(" .debits").hover(function () {
        $(" .center-right").css("background-color", "#4997cd");
    }, function () {
        $(" .center-right").css("background-color", "#fff");
    });

    $(".credits").hover(function () {
        $(".center-left").css("background-color", "#4997cd");
    }, function () {
        $(".center-left").css("background-color", "#fff");
    });


    $(".chat_on").click(function () {
        $(".Layout").show();
        $(".chat_on").hide(300);
    });

    // $(".chat_close_icon").click(function () {
    //     $(".Layout").hide();
    //     $(".chat_on").show(300);
    // });

    //scroll to div in retailers
    $('.countries-filter-bg-white ul li a, .countries-filter ul li a').click(function (e) {
        e.preventDefault();

        $('.countries-filter-bg-white ul li a, .countries-filter ul li a').removeClass('active');
        $('.countries-filter-bg-white .btn, .countries-filter .btn').removeClass('active');
        $("#btn_goto_last").removeClass('active');
        $(this).addClass('active');
        // $('html, body').animate({
        //     scrollTop: $($.attr(this, 'href')).offset().top - 110 //Add this
        // }, 300)
        $('html, body').animate({
            scrollTop: $('#all_section').offset().top - 110 //Add this
        }, 300)
    });

    $('.countries-filter-bg-white .btn, .countries-filter .btn').click(function (e) {

        e.preventDefault();

        $("#btn_goto_last").removeClass('active');
        $('.countries-filter-bg-white ul li a, .countries-filter ul li a').removeClass('active');

        $(this).addClass('active');
        $('html, body').animate({
            scrollTop: $('#list_all').offset().top - 110 //Add this
        }, 300)
    });

    $('.countries-filter-bg-white ul li a, .countries-filter ul li a').each(function (e) {
        if ($.attr(this, 'href') != "##_section") {
            if ($($.attr(this, 'href')).length === 0) {
                $(this).addClass('disable');
            }
        }
    });


    //scroll to last in list

    $("#btn_goto_last").click(function(e){

        $('.countries-filter-bg-white ul li a, .countries-filter ul li a').removeClass('active');
        $('.countries-filter-bg-white .btn, .countries-filter .btn').removeClass('active');
        $(this).addClass('active');

        $('html, body').animate({
            scrollTop: $('#list_all .section:last-child').position().top + 210 //Add this
        }, 300)
    })

    //active faq category
    $('.faqCategory').click(function (e) {
        e.preventDefault();
        $('.faqCategory').removeClass('active');
        $(this).addClass('active');
        $('html, body').animate({
            scrollTop: $('#faqDescription').offset().top - 110 //Add this
        }, 300)
    })

    $('.cc-main').click(function (e) {
        e.preventDefault();               
        $('html, body').animate({
            scrollTop: $('#nav-tabContent').offset().top - 110 //Add this
        }, 200)
    })
    $(document).on('click', function () {
        var navMain = $(".navbar-collapse");
        navMain.collapse('hide');
    });

    $(function () {
        $('[data-toggle="tooltip"]').tooltip({ boundary: 'window' })
    });

    
    $(".btn-more").on('click',function(e){
        
        if($(this).html() == "Show More"){
            $(this).html("Show Less"); 
        }else{
            $(this).html("Show More");
        }
        $(this.previousElementSibling).toggle();
    })

   

    // var segment = $(location).attr('href').split("#");
    // if(segment[1]!=undefined && segment[1] =="Claim-Policy"){
    //     $('html, body').animate({
    //         scrollTop: $('#'+segment[1]).offset().top - 90 //Add this
    //     }, 300)
    // }
}); 